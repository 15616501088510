import {
  colorsFragment, getImageWithAltFragment, richTextFragment, systemFields,
} from 'petdna-lib-uicomponents/hygraph';

const pageRedirectFromPetDnaFragment = `... on PageRedirectFromPetDna {
  ${systemFields}
  type
  redirectSiteName
  redirectDelay
  imageWithAlt {
    ${getImageWithAltFragment({})}
  }
  title {
    ${richTextFragment}
  }
  text {
    ${richTextFragment}
  }
  spinnerColor {
    ${colorsFragment}
  }
  titleColor {
    ${colorsFragment}
  }
  textColor {
    ${colorsFragment}
  }
}`;

const PAGE_REDIRECT_FROM_PETDNA_QUERY = `query PageRedirectFromPetDna($type: PageRedirectType) {
  pageRedirectFromPetDna(where: {type: $type}) {
    ${pageRedirectFromPetDnaFragment}
  }
}`;

export { pageRedirectFromPetDnaFragment, PAGE_REDIRECT_FROM_PETDNA_QUERY };
