import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useMatch } from 'react-router-dom';
import './SiteHeader.scss';
import SiteLogo from 'components/SiteLogo/SiteLogo';
import { observer } from 'mobx-react-lite';
import ProfileMenu from 'components/SiteHeader/ProfileMenu';
import { useStores } from 'stores/useStores';
import classNames from 'classnames';
import { useGetUserProfile } from 'hooks/useGetUserProfile';
import { useLoadNavbar } from 'hooks/useLoadNavbar';
import { useGetUserPetProfiles } from 'hooks/useGetUserPetProfiles';
import DashboardNavigation from 'components/DashboardNavigation/DashboardNavigation';
import { Client } from '@amityco/ts-sdk';
import { useGetAmityUser } from 'hooks/useAmityGetUser';
import { useCreateAmityClient } from 'hooks/useCreateAmityClient';

import { toJS } from 'mobx';
import { PromoBanner } from 'petdna-lib-uicomponents/components';
import { useLoadPromoBanner } from 'hooks/useLoadPromoBanner';
import MobileMenu from './MobileMenu';

const SiteHeader = observer(() => {
  const { t } = useTranslation();
  const { navbarData } = useLoadNavbar();
  const { testsStore } = useGetUserPetProfiles();
  const { petProfiles } = testsStore;
  const { userStore } = useGetUserProfile();
  const { userProfile } = userStore;
  const { sessionStore } = useStores();
  const { sessionValid, checkingSession, sessionChecked } = sessionStore;
  const isLoggedIn = sessionValid;
  const [unreadCount, setUnreadCount] = useState(0);
  const amityStore = useCreateAmityClient();
  const { promoBanner } = useLoadPromoBanner();
  const offerPage = useMatch('/offer');

  useGetAmityUser();

  const renderMarketingMenu = useCallback(() => {
    if (petProfiles && toJS(petProfiles).length !== 0) {
      return null;
    }

    return (
      <ul className="navbar-nav me-auto d-none d-lg-flex">
        {navbarData?.leftMenuItems?.map((navbarItem) => (
          <li className="nav-item" key={navbarItem.name}>
            <a
              className="nav-link"
              href={navbarItem.externalLink || `/${navbarItem.link}`}
            >
              {navbarItem.label}
            </a>
          </li>
        ))}
      </ul>
    );
  }, [navbarData?.leftMenuItems, petProfiles]);

  const renderMainMenu = useCallback(() => {
    if (toJS(petProfiles)?.length === 0) {
      return null;
    }

    return (
      <div className="d-none d-lg-flex dashboard-nav-container">
        <DashboardNavigation unreadCount={unreadCount} />
      </div>
    );
  }, [petProfiles, unreadCount]);

  useEffect(() => {
    const createClient = async () => {
      if (userProfile?.UserId !== undefined && !amityStore.client) {
        await amityStore.createAmityClient(userProfile?.UserId, userProfile?.PetDNADisplayName);
      }
    };
    if (isLoggedIn && petProfiles?.length) createClient();

    return () => {
      if (isLoggedIn) Client.stopUnreadSync();
    };
  }, [
    userProfile?.UserId,
    amityStore.client,
    petProfiles?.length,
    isLoggedIn,
    userProfile?.PetDNADisplayName,
    amityStore,
  ]);

  // Get total unread count for user
  useEffect(() => {
    const handleLiveObject = (liveObject: Amity.LiveObject<Amity.UserUnread | undefined>) => {
      const newUnreadCount = liveObject.data?.unreadCount;
      setUnreadCount(newUnreadCount ?? 0);
    };
    if (amityStore.client && amityStore.isConnected && isLoggedIn) {
      Client.getUserUnread(handleLiveObject);
    }
  }, [amityStore.client, amityStore.isConnected, isLoggedIn]);

  if (!sessionChecked || checkingSession) {
    return null;
  }

  return (
    <>
      <nav className={classNames(!isLoggedIn && 'bg-primary', 'navbar navbar-expand-lg navbar-site')} aria-label="Desktop menu">
        <a className="skip-main-content" href="#main">{t('navbar.skipToContent')}</a>
        <div className={classNames(isLoggedIn && 'header-container', 'container')}>
          <button
            className="btn nav-link btn-primary-text btn-icon-only d-lg-none me-12"
            type="button"
            aria-label="Menu"
            data-bs-toggle="offcanvas"
            data-bs-target="#mobileHeaderMenu"
            aria-controls="mobileHeaderMenu"
          >
            <span className="icon icon-menu" />
          </button>
          <a
            className="d-flex navbar-brand p-4 me-auto me-lg-24 main-navbar"
            href="/"
            aria-label="Know Your Pet DNA Home"
          >
            <SiteLogo height={40} />
          </a>
          {
            isLoggedIn && (
              <>
                {renderMainMenu()}
                {renderMarketingMenu()}
              </>
            )
          }
          <ul className="navbar-nav flex-shink-0 flex-row">
            {isLoggedIn
              ? (
                <li className="nav-item d-none d-lg-flex">
                  <button
                    className="btn btn-primary-text profile-btn btn-sm d-flex align-items-center"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#profileMenu"
                    aria-controls="profileMenu"
                    type="button"
                  >
                    <i className="icon icon-person icon-16 me-4" />
                    {userProfile?.DisplayName
                      ? userProfile.DisplayName
                      : (
                        <span className="placeholder-wave name-placeholder">
                          <span className="placeholder w-100" />
                        </span>
                      )}
                  </button>
                </li>
              )
              : null}
            <li className="nav-item" hidden={!isLoggedIn}>
              <a
                className="btn btn-dark btn-sm mx-8"
                href="/mypets/checkout"
              >
                {t('add.pet')}
              </a>
            </li>
            <li className="nav-item d-none d-lg-flex">
              <NavLink
                className="btn btn-light btn-sm"
                hidden={!isLoggedIn}
                to="/register"
                end
              >
                {t('navbar.registerKit')}
              </NavLink>
            </li>
          </ul>

        </div>
      </nav>
      {offerPage ? null : <PromoBanner promoBanner={promoBanner} />}
      <MobileMenu
        links={navbarData?.leftMenuItems}
        petProfiles={petProfiles}
        unreadCount={unreadCount}
      />
      <ProfileMenu />
    </>
  );
});

export default SiteHeader;
