import React, { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'constants/policy';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import apiClient from 'apiClient/index';
import { ERedirectType } from 'stores/types/Pages';
import { HEALTH_REPORT_PATHNAME } from 'hooks/useHealthReportUrl';
import Main from './Main/Main';

const Breeds = lazy(() => import('routes/Breeds/Breeds'));
const BreedInfo = lazy(() => import('routes/BreedInfo/BreedInfoPage'));
const Chat = lazy(() => import('routes/Chat/Chat'));
const Checkout = lazy(() => import('routes/Checkout/Checkout'));
const Dashboard = lazy(() => import('routes/Dashboard/Dashboard'));
const FAQs = lazy(() => import('routes/FAQs/FAQs'));
const Home = lazy(() => import('routes/Home/Home'));
const Policy = lazy(() => import('routes/Policy/Policy'));
const Matches = lazy(() => import('routes/Matches/Matches'));
const NotFound = lazy(() => import('routes/NotFound/NotFound'));
const Profile = lazy(() => import('routes/Profile/Profile'));
const ContactUs = lazy(() => import('routes/ContactUs/ContactUs'));
const UpdateProfile = lazy(() => import('routes/UpdateProfile/UpdateProfile'));
const Recollect = lazy(() => import('routes/Recollect/Recollect'));
const RecollectConfirmation = lazy(() => import('routes/RecollectConfirmation/RecollectConfirmation'));
const Register = lazy(() => import('routes/Register/Register'));
const SignIn = lazy(() => import('routes/SignIn/SignIn'));
const Traits = lazy(() => import('routes/Traits/Traits'));
const TraitInfo = lazy(() => import('routes/TraitInfo/TraitInfoPage'));
const Partnerships = lazy(() => import('routes/Partnerships/Partnerships'));
const Offer = lazy(() => import('routes/Offer/Offer'));
const CoBranded = lazy(() => import('routes/CoBranded/CoBranded'));
const HealthReport = lazy(() => import('routes/HealthReport/HealthReport'));
const HealthIndividualReport = lazy(() => import('routes/HealthIndividualReport/HealthIndividualReport'));
const Redirect = lazy(() => import('routes/Redirect/Redirect'));

interface IPetdnaRouterProps {
  updateBg: () => void
}

const petdnaRouter = ({ updateBg }: IPetdnaRouterProps) => createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: 'sign-in',
        element: (
          <Suspense>
            <SignIn />
          </Suspense>
        ),
      },
      {
        path: 'offer',
        element: (
          <Suspense>
            <Offer />
          </Suspense>
        ),
      },
      {
        path: 'co-branded',
        element: (
          <Suspense>
            <CoBranded />
          </Suspense>
        ),
      },
      {
        path: '',
        element: <ProtectedRoute />,
        children: [
          {
            path: '',
            element: (
              <Suspense>
                <Home />
              </Suspense>
            ),
          },
          {
            path: 'faqs',
            element: (
              <Suspense>
                <FAQs />
              </Suspense>
            ),
          },
          {
            path: 'privacy',
            element: (
              <Suspense>
                <Policy policyId={PRIVACY_POLICY} />
              </Suspense>
            ),
          },
          {
            path: 'terms_conditions',
            element: (
              <Suspense>
                <Policy policyId={TERMS_AND_CONDITIONS} />
              </Suspense>
            ),
          },
          {
            path: 'checkout',
            element: (
              <Suspense>
                <Checkout />
              </Suspense>
            ),
          },
          {
            path: 'register',
            element: (
              <Suspense>
                <Register />
              </Suspense>
            ),
          },
          {
            path: 'partnerships',
            element: (
              <Suspense>
                <Partnerships />
              </Suspense>
            ),
          },
          {
            path: 'all/chat',
            element: (
              <Suspense>
                <Chat />
              </Suspense>
            ),
          },
          {
            path: ':testId',
            caseSensitive: true,
            children: [
              {
                path: '',
                element: (
                  <Suspense>
                    <Dashboard />
                  </Suspense>
                ),
              },
              {
                path: 'update-profile',
                element: (
                  <Suspense>
                    <UpdateProfile />
                  </Suspense>
                ),
              },
              {
                path: 'recollect',
                element: (
                  <Suspense>
                    <Recollect />
                  </Suspense>
                ),
              },
              {
                path: 'recollect-confirmation',
                element: (
                  <Suspense>
                    <RecollectConfirmation updateBg={updateBg} />
                  </Suspense>
                ),
              },
              {
                path: 'breeds',
                element: (
                  <Suspense>
                    <Breeds />
                  </Suspense>
                ),
              },
              {
                path: 'breeds/:breedId',
                element: (
                  <Suspense>
                    <BreedInfo />
                  </Suspense>
                ),
              },
              {
                path: 'traits',
                element: (
                  <Suspense>
                    <Traits />
                  </Suspense>
                ),
              },
              {
                path: 'traits/:traitId',
                element: (
                  <Suspense>
                    <TraitInfo />
                  </Suspense>
                ),
              },
              {
                path: HEALTH_REPORT_PATHNAME,
                children: [
                  {
                    index: true,
                    element: (
                      <Suspense>
                        <HealthReport />
                      </Suspense>
                    ),
                  },
                  {
                    caseSensitive: true,
                    path: ':traitId',
                    element: (
                      <Suspense>
                        <HealthIndividualReport />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'matches',
                element: (
                  <Suspense>
                    <Matches />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'profile',
            caseSensitive: true,
            element: (
              <Suspense>
                <Profile />
              </Suspense>
            ),
          },
          {
            path: 'contact_us',
            element: (
              <Suspense>
                <ContactUs />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '*',
        element: (
          <Suspense>
            <NotFound />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/redirect',
    element: <Redirect />,
    loader: async ({ request }) => {
      const type = new URL(request.url).searchParams.get('type')?.toLocaleUpperCase() as unknown as ERedirectType | null;

      if (!type) return null;
      try {
        const data = await apiClient.page.getRedirectFromPetDNAPage({ type });
        return data.data.data.pageRedirectFromPetDna;
      } catch (error) {
        return null;
      }
    },
  },
], {
  basename: '/mypets',
});

export default petdnaRouter;
