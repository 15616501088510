import { HealthTraitListFiltersHashed } from 'routes/HealthReport/components/Filters/Filters';
import { BreedResult } from 'stores/types/Breeds';
import { HealthTraitBodyFunction, HealthTraitResultTypeWithCount } from 'stores/types/Traits';

import { PartialExcept } from 'constants/types/helpers';

export interface IHealthFiltersSerializerValue {
  statuses?: PartialExcept<HealthTraitResultTypeWithCount, 'traitPredictionId'>[];
  breeds?: PartialExcept<BreedResult, 'id'>[];
  bodyFunctions?: PartialExcept<HealthTraitBodyFunction, 'id'>[];
  search?: string;
}

export const healthFiltersHashFunctions: Record<
keyof IHealthFiltersSerializerValue,
(value: any) => string
> = {
  statuses: (value: HealthTraitResultTypeWithCount) => value.traitPredictionId,
  breeds: (value: BreedResult) => value.id,
  bodyFunctions: (value: HealthTraitBodyFunction) => value.id,
  search: (value: string) => value,
};

export const healthFiltersSerializer = (newValue: IHealthFiltersSerializerValue): string => {
  const hashed = Object.keys(newValue).reduce<HealthTraitListFiltersHashed>((acc, filter) => {
    const value = newValue[filter];

    if (typeof value === 'string' && filter === 'search' && value) {
      acc[filter] = value;
      return acc;
    }

    if (!Array.isArray(value)) {
      return acc;
    }

    acc[filter] = value.map(healthFiltersHashFunctions[filter]) as any;

    return acc;
  }, {});
  return JSON.stringify(hashed);
};
