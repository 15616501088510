import { healthFiltersSerializer, IHealthFiltersSerializerValue } from 'helpers/healthFilters';
import { useMemo } from 'react';

export const HEALTH_REPORT_PATHNAME = 'health-report';
export const HEALTH_FILTERS_KEY = 'filters';

export const useHealthReportUrl = (filters: IHealthFiltersSerializerValue) => useMemo(() => {
  const search = new URLSearchParams();
  search.append(HEALTH_FILTERS_KEY, healthFiltersSerializer(filters));

  return { url: `${HEALTH_REPORT_PATHNAME}?${search.toString()}`, search };
}, [filters]);
