import { Trait } from 'stores/types/Traits';
import { useEffect, useMemo } from 'react';
import { useStores } from 'stores/useStores';
import { useGetBreeds } from 'hooks/useGetBreeds';
import { useGetPetProfile } from 'hooks/useGetPetProfile';
import { Profile } from 'stores/types/Profiles';
import { BreedResults } from 'stores/types/Breeds';

const useHealthTraitsPredictions = (): {
  traits: Trait[] | null;
  loading: boolean;
  entitled: boolean;
  petProfile: Profile | null;
  breedResults: BreedResults | null;
} => {
  const { healthReportStore } = useStores();
  const { testsStore } = useGetPetProfile();
  const { breedStore } = useGetBreeds();
  const { petProfile } = testsStore;
  const { breedResults } = breedStore;

  const { healthTraitsData, healthReportLoading } = healthReportStore;

  useEffect(() => {
    if (!breedResults || !petProfile) {
      return;
    }

    healthReportStore.getHealthReport(petProfile, breedResults.breeds);
  }, [healthReportStore, breedResults, petProfile]);

  return useMemo(() => {
    if (!healthTraitsData || healthReportLoading || !petProfile) {
      return {
        petProfile,
        breedResults,
        loading: healthReportLoading,
        traits: null,
        entitled: false,
      };
    }

    const data = healthTraitsData.get(petProfile?.testGUID);
    return {
      petProfile,
      breedResults,
      loading: healthReportLoading,
      traits: data?.traits ?? null,
      entitled: data?.entitled ?? false,
    };
  }, [breedResults, healthReportLoading, healthTraitsData, petProfile]);
};

export default useHealthTraitsPredictions;
