export const SECTION_HEALTH_TRAIT_AFFECTED_BREEDS_QUERY = `SectionHealthTraitAffectedBreeds {
  id
  __typename
  name
  title {
    html
  }
  undeterminedResultTitle {
    html
  }
  accordionTitle {
    html
  }
  text {
    html
  }
  noRiskBreedsText {
    html
  }
  titleColor {
    hex
  }
  mainColor {
    hex
  }
  subtleColor {
    hex
  }
  titleFontSize
  textFontSize
  sectionClassName
  titleClassName
  textClassName
}`;
