import apiClient from 'apiClient/index';
import {
  Client, API_REGIONS, UserRepository, ChannelRepository,
} from '@amityco/ts-sdk';

import { configure, makeAutoObservable, runInAction } from 'mobx';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

const getBlockedUsers = (user?: Amity.User): string[] => user?.metadata?.blockedUsers || [];

const hasBlockedUsers = (
  user?: Amity.User,
): boolean => Boolean(user) && Boolean(getBlockedUsers(user).length);

class AmityStore {
  amityAuthToken = '';
  amityChannelId = '';
  client: Amity.Client | undefined;
  isConnected = false;

  channels: Amity.Channel[] | undefined;
  hasMoreChannels: boolean;
  onNextPageChannels: () => void;

  membersForChannels: Record<string, Amity.Membership<'channel'>[]>;
  amityUser: Amity.LiveObject<Amity.User> | undefined;

  hasChannel = false;
  profilesByChat: Map<string, { name: string }> | null = null;

  constructor() {
    makeAutoObservable(this);

    this.membersForChannels = {};
    this.hasMoreChannels = false;
    this.onNextPageChannels = () => {};
  }

  filterBannedChannel = async (newChannels: Amity.Channel[]): Promise<Amity.Channel[]> => {
    if (!newChannels.length) {
      return newChannels;
    }

    if (!hasBlockedUsers(this.amityUser?.data)) {
      return newChannels;
    }

    const blockedUsers = new Set(getBlockedUsers(this.amityUser?.data));
    const channelsWithMembers = await Promise.all(newChannels.map(async (channel) => ({
      channel,
      members: await this.getMembersForChannel(channel.channelId),
    })));

    return channelsWithMembers
      .filter(({ members }) => !members.find((member) => blockedUsers.has(member.userId)))
      .map(({ channel }) => channel);
  };

  updateChannelsState = async (
    channels: Amity.Channel[],
    hasMore?: boolean,
    onNextPage?: () => void,
  ) => {
    this.channels = await this.filterBannedChannel(channels);
    await this.getChannelProfile(channels);
    this.hasMoreChannels = Boolean(hasMore);
    this.onNextPageChannels = onNextPage || (() => {});
  };

  getChannels = () => ChannelRepository.getChannels({
    membership: 'member',
    limit: 10,
    isDeleted: false,
  }, ({
    data: channels,
    hasNextPage,
    onNextPage,
  }) => {
    this.updateChannelsState(channels, hasNextPage, onNextPage);
  });

  getMembersForChannel = (channelId: string) => {
    if (this.membersForChannels[channelId]) {
      return this.membersForChannels[channelId];
    }

    let resolved = false;

    return new Promise<Amity.Membership<'channel'>[]>((resolve) => {
      ChannelRepository.Membership.getMembers({
        channelId,
      }, ({ data: members }) => {
        this.membersForChannels[channelId] = members;

        if (!resolved) {
          resolved = true;
          resolve(members);
        }
      });
    });
  };

  setAmityUser = (user: Amity.LiveObject<Amity.User>) => {
    this.amityUser = user;
  };

  getAmityUser = (userId?: string) => {
    if (userId && this.client && this.isConnected) {
      const unsubscribe = UserRepository.getUser(userId, this.setAmityUser);
      unsubscribe();
    }
    return this.amityUser;
  };

  getAmityAuthToken = async () => {
    const amityResponse = await apiClient.amity.getAmityAuthToken();
    if (amityResponse?.data) {
      this.amityAuthToken = amityResponse.data.amityAuthToken;
    }
    return this.amityAuthToken;
  };

  getAmityChannel = async (testGuid: string, toTestGuid: string) => {
    const amityResponse = await apiClient.amity.getAmityChannel(testGuid, toTestGuid);
    if (amityResponse?.data) {
      this.amityChannelId = amityResponse.data.channelId;
      return this.amityChannelId;
    }
    return null;
  };

  createAmityClient = async (userId: string, petDisplayName?: string) => {
    const token = await this.getAmityAuthToken();

    this.client = Client.createClient(`${process.env.REACT_APP_AMITY_API_KEY}`, API_REGIONS.US);
    const sessionHandler: Amity.SessionHandler = {
      sessionWillRenewAccessToken(renewal: Amity.AccessTokenRenewal) {
        // for details on other renewal methods check session handler
        renewal.renew();
      },
    };

    this.isConnected = await Client.login(
      {
        userId,
        displayName: petDisplayName, // optional
        authToken: token, // only required if using secure mode
      },
      sessionHandler,
    );

    this.getAmityUser(userId);

    await Client.startUnreadSync();
  };

  amityLogout = async () => {
    if (!this.client) {
      return;
    }

    await Client.logout();
  };

  getHasAnyChannel = async () => {
    const res = await apiClient.amity.getHasAnyChannel();

    if (res.data) {
      this.hasChannel = Boolean(res.data?.hasChannel);
    }
    return this.hasChannel;
  };

  getChannelProfile = async (channels: Amity.Channel[]) => {
    if (!channels?.length) return;

    const channelIdsQuery = new URLSearchParams();
    channels.forEach(({ channelId }) => {
      channelIdsQuery.append('channelIds', channelId);
    });

    const profiles = await apiClient.tests.getPetProfilesByChannels(channelIdsQuery);
    runInAction(() => {
      this.profilesByChat = new Map(
        profiles?.data?.profiles.map(({ channelId, ...rest }) => ([channelId, rest])),
      );
    });
  };
}

export default AmityStore;
