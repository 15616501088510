import { useNavigate, useParams } from 'react-router-dom';
import useHealthTraitsPredictions from 'hooks/useHealthTraitsPredictions';
import { useEffect, useMemo } from 'react';

export interface UseHealthFeatureOptions {
  redirectTo?: string;
}

const useHealthFeature = ({ redirectTo }: UseHealthFeatureOptions = {}) => {
  const navigate = useNavigate();
  const { testId, traitId } = useParams();
  const {
    traits, loading, entitled, petProfile, breedResults,
  } = useHealthTraitsPredictions();

  useEffect(() => {
    if (!entitled && !loading) {
      navigate(redirectTo ?? `/${testId ?? ''}`);
    }
  }, [navigate, entitled, loading, redirectTo, testId]);

  return useMemo(() => ({
    loading,
    petProfile,
    breedResults,
    predictions: traits,
    prediction: traitId
      ? traits?.find((t) => t.id.toLowerCase() === traitId.toLowerCase())
      : undefined,
  }), [petProfile, breedResults, traits, traitId, loading]);
};

export default useHealthFeature;
