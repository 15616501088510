import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './DisplayNameModal.scss';
import { useStores } from 'stores/useStores';
import Modal from 'react-bootstrap/Modal';

type IDisplayModal = {
  isNameModalOpen: boolean,
  onClose: () => void,
  matchId?: string,
  id: string
};

const DisplayNameModal = ({
  isNameModalOpen,
  onClose,
  matchId,
  id,
}: IDisplayModal) => {
  const { t } = useTranslation();
  const [petDNADisplayName, setPetDNADisplayName] = useState<string>('');
  const [modalTitle, setModalTitle] = useState<string>(t('matches.displayNameModal.title'));
  const [modalSubTitle, setModalSubTitle] = useState<string>(t('matches.displayNameModal.subtitle'));
  const [isGetStarted, setIsGetStarted] = useState<boolean>(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { userStore } = useStores();

  useEffect(() => {
    if (petDNADisplayName.length > 32) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [petDNADisplayName]);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      savePetDNADisplayName();
    }
  };

  const goToChat = () => {
    onClose();
    if (matchId) {
      navigate(`/all/chat?toTestId=${matchId}&fromTestId=${id}`);
    } else {
      navigate('/all/chat');
    }
  };

  const savePetDNADisplayName = async () => {
    userStore.setPetDNADisplayName(petDNADisplayName).then((res: any) => {
      setIsGetStarted(true);
      setModalTitle(t('matches.displayNameModal.titleGetStarted', { name: petDNADisplayName }));
      setModalSubTitle(t('matches.displayNameModal.subtitleGetStarted'));
    })
      .catch((err) => {
        setModalTitle(t('matches.displayNameModal.titleDuplicate'));
        setModalSubTitle(t('matches.displayNameModal.subtitleDuplicate'));
      });
  };

  return (
    <Modal
      show={isNameModalOpen}
      onHide={onClose}
      backdrop="static"
      centered
      className="name-modal"
    >
      <Modal.Header className="name-modal-header" closeButton>
        <Modal.Title>
          <h2 className="name-modal_title" id="uploadImageModalLabel">
            {modalTitle}
          </h2>
        </Modal.Title>
        <button
          type="button"
          className="modal_close_button"
          onClick={onClose}
          aria-label="Close"
        >
          <span className="icon icon-close name-icon-close" />
        </button>
      </Modal.Header>
      <Modal.Body className="name-modal name-modal-body">
        <p className="modal-subtitle name-modal_subtitle" id="uploadImageModalLabel">
          {modalSubTitle}
        </p>
        {!isGetStarted
          && (
            <input
              className="name_modal_input_name"
              placeholder={t('matches.displayNameModal.placeholder')}
              type="text"
              onKeyDown={onKeyDown}
              onChange={(e) => setPetDNADisplayName(e.target.value)}
              value={petDNADisplayName}
            />
          )}
        {isSubmitDisabled && <p className="length-warning">Please enter a display name shorter than 32 characters.</p>}
      </Modal.Body>
      <Modal.Footer className="name-modal name-modal-footer">
        {isGetStarted
          ? (
            <button
              type="button"
              className="btn btn-dark btn-sm me-8 btn-done"
              aria-label={t('close')}
              onClick={goToChat}
              data-bs-dismiss="modal"
            >
              {t('matches.displayNameModal.buttonGetStarted')}
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-dark btn-sm me-8 btn-done"
              aria-label={t('close')}
              onClick={savePetDNADisplayName}
              data-bs-dismiss="modal"
              disabled={isSubmitDisabled}
            >
              {t('matches.displayNameModal.button')}
            </button>
          )}
      </Modal.Footer>
    </Modal>
  );
};

export default DisplayNameModal;
