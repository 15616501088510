export interface PromiseMetadata<T = unknown> {
  promise: Promise<T>;
}

export class DeduplicationPool<T = unknown> {
  private promisesPool: Map<string, PromiseMetadata<T>> = new Map<string, PromiseMetadata<T>>();

  async runTask(
    key: string,
    task: () => Promise<T>,
  ): Promise<T> {
    if (this.promisesPool.has(key)) {
      return this.promisesPool.get(key)!.promise;
    }

    // eslint-disable-next-line no-async-promise-executor
    const promise = new Promise<T>(async (resolve, reject) => {
      try {
        const result = await task();
        resolve(result);
      } catch (err) {
        reject(err);
      } finally {
        // deleting promise from the pool when settled
        this.promisesPool.delete(key);
      }
    });
    this.promisesPool.set(key, {
      promise,
    });

    return promise;
  }
}
