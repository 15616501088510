import {
  configure,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import apiClient from 'apiClient/index';
import { TSupportedLocales } from 'petdna-lib-uicomponents/constants';
import { Profile } from 'stores/types/Profiles';
import { BreedResult } from 'stores/types/Breeds';
import { DeduplicationPool } from 'helpers/DeduplicationPool/DeduplicationPool';
import { HealthTraitPrediction } from 'hooks/useHealthTraitRecords';
import {
  HealthTraitBodyFunction,
  HealthTraitInfo,
  HealthTraitInfoFilters,
  HealthTraitInheritance,
  HealthTraitInheritanceResult,
  HealthTraitResultType,
  Trait,
} from './types/Traits';
import { IDashboardHealthReport } from './types/Health';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

type HealthTraitData = { traits?: Trait[], entitled: boolean };

class HealthReportStore {
  healthReportLoading = true;
  healthTraitsData = new Map<string, HealthTraitData>();
  eligible = false;
  dashboardHealthReport: IDashboardHealthReport | null = null;
  dashboardHealthReportLoading = true;
  healthTraitInfo = new Map<string, HealthTraitInfo>();
  bodyFunctions: HealthTraitBodyFunction[] | null = null;
  healthTraitResultTypes: HealthTraitResultType[] | null = null;
  healthTraitInfoLoading = true;
  healthTraitInfoList: HealthTraitInfo[] | null = null;

  deduplicationPool: DeduplicationPool = new DeduplicationPool();

  healthTraitsForInheritance: Map<
  string,
  Pick<HealthTraitInfo, 'traitId' | 'affectedBreeds' | 'inheritance'>
  > | null = null;

  healthTraitInheritanceResults: HealthTraitInheritanceResult[] | null = null;

  constructor() {
    makeAutoObservable(this, {
      healthTraitInfo: observable.deep,
      healthTraitsData: observable.deep,
    });
  }

  async getHealthTraitsForInheritance() {
    if (this.healthTraitsForInheritance) {
      return;
    }

    const response = await apiClient.healthTraits.getHealthTraitsForInheritance();
    runInAction(() => {
      this.healthTraitsForInheritance = new Map(
        response.data?.data.healthTraits.map((trait) => [trait.traitId.toLowerCase(), trait]) ?? [],
      );
    });
  }

  async getHealthTraitInheritanceResults() {
    if (this.healthTraitInheritanceResults) {
      return;
    }

    const response = await apiClient.healthTraits.getHealthTraitInheritanceResults();
    runInAction(() => {
      this.healthTraitInheritanceResults = response.data?.data.healthTraitInheritanceResults;
    });
  }

  async setupStore(): Promise<void> {
    return await this.deduplicationPool.runTask(
      'setupStore',
      async () => {
        await this.getHealthTraitInheritanceResults();
        await this.getHealthTraitsForInheritance();
      },
    ) as Promise<void>;
  }

  getHealthReport = async (
    profile: Profile,
    breeds: BreedResult[],
  ) => {
    if (this.healthTraitsData.has(profile.testGUID)) {
      return;
    }

    await this.deduplicationPool.runTask(
      `${profile.testGUID}-${breeds.map((breed) => breed.id).join(',')}`,
      async () => {
        this.healthReportLoading = true;

        const response = await apiClient.healthTraits.getHealthReport(profile.testGUID);
        await runInAction(async () => {
          if (response.data) {
            this.healthTraitsData.set(profile.testGUID, {
              ...response.data,
              traits: response.data.entitled
                ? await this.updatePredictionsForInheritance(
                  response.data.traits,
                  profile,
                  breeds,
                )
                : response.data.traits,
            });
          }
          this.healthReportLoading = false;
        });
      },
    );
  };

  async getHealthTraitInheritanceResultForPrediction(
    trait: Trait,
    petProfile: Profile,
    breeds: BreedResult[],
  ): Promise<HealthTraitInheritanceResult | null> {
    await this.setupStore();

    return await this.deduplicationPool.runTask(
      `${trait.id}-${petProfile.testGUID}-${breeds.map((breed) => breed.id).join(',')}`,
      async () => {
        const traitInfo = this.healthTraitsForInheritance?.get(trait.id.toLowerCase());
        if (!traitInfo) {
          return null;
        }

        const breedsIntersect = !traitInfo.affectedBreeds.length
          || traitInfo.affectedBreeds.some((breed) => breeds.find((b) => b.id === breed.breedID));

        return this.healthTraitInheritanceResults?.find((result) => {
          // special case for undetermined traits
          if (trait.prediction === HealthTraitPrediction.UNDETERMINED
            && result.inheritance === HealthTraitInheritance.UNDETERMINED
          ) {
            return true;
          }

          if (result.inheritance !== traitInfo.inheritance) {
            return false;
          }

          if (!trait.prediction.endsWith(result.predictionType.traitPredictionId)) {
            return false;
          }

          // if gender is defined in result, should match with pet's gender
          if (result.gender && petProfile.bioSex.toLowerCase() !== result.gender) {
            return false;
          }

          if (result.hasMatchingBreeds !== breedsIntersect) {
            return false;
          }

          return true;
        }) ?? null;
      },
    ) as Promise<HealthTraitInheritanceResult | null>;
  }

  async updatePredictionsForInheritance(
    traits: Trait[] | undefined,
    petProfile: Profile,
    breeds: BreedResult[],
  ): Promise<Trait[] | undefined> {
    if (!traits) {
      return traits;
    }

    await this.setupStore();
    await this.getHealthTraitResultType();

    const updatedTraits = await Promise.all(traits.map(async (trait) => {
      // Mark traits with unknown prediction as undetermined
      if (!this.healthTraitResultTypes
        ?.find((type) => trait.prediction.endsWith(type.traitPredictionId))
      ) {
        return {
          id: trait.id.toLowerCase(),
          prediction: HealthTraitPrediction.UNDETERMINED,
        };
      }

      const result = await this.getHealthTraitInheritanceResultForPrediction(
        trait,
        petProfile,
        breeds,
      );

      if (!result) {
        return trait;
      }

      return {
        id: trait.id.toLowerCase(),
        prediction: result.displayType.traitPredictionId,
      };
    }));

    return updatedTraits.concat(this.getUndeterminedTraits(updatedTraits));
  }

  private getUndeterminedTraits(traits: Trait[]): Trait[] {
    const determinedTraits = new Set(traits.map((t) => t.id));
    return Array.from(this.healthTraitsForInheritance?.keys() ?? [])
      .filter((traitId: string) => !determinedTraits.has(traitId))
      .map((traitId: string) => ({ id: traitId, prediction: HealthTraitPrediction.UNDETERMINED }));
  }

  getHealthDashboardReport = async (
    profile: Profile,
    breeds: BreedResult[],
    locales: TSupportedLocales[],
  ) => {
    await this.getHealthReport(profile, breeds);

    runInAction(async () => {
      const data = this.healthTraitsData.get(profile.testGUID);

      if (!data) {
        this.dashboardHealthReportLoading = false;
        return;
      }

      // TODO: fix loading state
      // this.dashboardHealthReportLoading = true;
      const {
        data: { data: { dashboardHealthReport } },
      } = await apiClient.healthTraits.getDashboardHealthReport(locales);

      if (dashboardHealthReport) {
        this.dashboardHealthReport = dashboardHealthReport;
      }

      if (!data.entitled) {
        const queryParams = this.dashboardHealthReport?.healthReportPrice?.queryParams;
        const offerId = new URLSearchParams(queryParams).get('offers') ?? '';

        await this.getEligibility(profile.testGUID, offerId);
      }

      this.dashboardHealthReportLoading = false;
    });
  };

  getEligibility = async (testId: string, offerId: string) => {
    // TODO: change to actual eligibility when eligibility endpoint will be fixed
    if (offerId) {
      this.eligible = true;
      return;
    }

    if (!testId || !offerId) {
      this.eligible = false;
      return;
    }

    const { data: { eligible } } = await apiClient.healthTraits.getEligibility(testId, offerId);

    runInAction(() => {
      this.eligible = Boolean(eligible);
    });
  };

  async getHealthTraitInfo(traitId: string) {
    return (await this.deduplicationPool.runTask(
      `${traitId}`,
      async () => {
        if (this.healthTraitInfo.has(traitId)) {
          return this.healthTraitInfo.get(traitId);
        }

        const traitResponse = await apiClient.healthTraits.getHealthTraitInfo(traitId);
        if (traitResponse?.data.data.healthTraits?.length) {
          const data = traitResponse.data.data.healthTraits[0];

          runInAction(() => {
            this.healthTraitInfo.set(traitId, data);
          });

          return data;
        }

        return undefined;
      },
    )) as (HealthTraitInfo | undefined);
  }

  async getHealthTraitResultType() {
    if (this.healthTraitResultTypes) {
      return;
    }

    await this.deduplicationPool.runTask(
      'healthTraitResultType',
      async () => {
        const response = await apiClient.healthTraits.getHealthTraitResultTypes();
        if (response?.data.data.healthTraitResultTypes?.length) {
          runInAction(() => {
            this.healthTraitResultTypes = response.data.data.healthTraitResultTypes;
          });
        }
      },
    );
  }

  async getBodyFunctions() {
    if (this.bodyFunctions) {
      return;
    }

    await this.deduplicationPool.runTask(
      'bodyFunctions',
      async () => {
        const response = await apiClient.healthTraits.getBodyFunctions();
        if (response?.data.data.healthTraitBodyFunctions?.length) {
          runInAction(() => {
            this.bodyFunctions = response.data.data.healthTraitBodyFunctions;
          });
        }
      },
    );
  }

  async listHealthTraitsInfo(filters: HealthTraitInfoFilters) {
    this.healthTraitInfoLoading = true;

    return (await this.deduplicationPool.runTask(
      `listHealthTraits-${JSON.stringify(filters)}`,
      async () => {
        const response = await apiClient.healthTraits.listHealthTraitInfo(filters);

        runInAction(() => {
          this.healthTraitInfoList = response.data.data.healthTraits;
          this.healthTraitInfoLoading = false;
        });

        return response.data.data.healthTraits;
      },
    )) as HealthTraitInfo[];
  }
}

export default HealthReportStore;
