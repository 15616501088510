import { useCallback, useMemo } from 'react';

import { useAuth } from 'hooks/useAuth';
import { getDomain } from 'helpers/getDomain';

export enum SignInType {
  SignIn,
  Signup,
}

const appendSearchParams = (url: URL, searchParams: Record<string, any>): URL => {
  Object.keys(searchParams).forEach((param: string) => {
    url.searchParams.append(param, searchParams[param].toString());
  });

  return url;
};

const getWindowWidth = () => {
  if (window.innerWidth) {
    return window.innerWidth;
  }
  return document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
};

const getWindowHeight = () => {
  if (window.innerHeight) {
    return window.innerHeight;
  }
  return document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;
};

const getAuthWindowSpecs = () => {
  const authWindowSpecs = {
    width: 500,
    height: 500,
    centerscreen: true,
    chrome: true,
    dialog: true,
    alwaysRaised: true,
    left: 0,
    top: 0,
  };

  const dualScreenLeft = window.screenLeft !== undefined
    ? window.screenLeft : (window.screen as any).left;
  const dualScreenTop = window.screenTop !== undefined
    ? window.screenTop : (window.screen as any).top;
  const width = getWindowWidth();
  const height = getWindowHeight();

  authWindowSpecs.left = ((width / 2) - (authWindowSpecs.width / 2)) + dualScreenLeft;
  authWindowSpecs.top = ((height / 2) - (authWindowSpecs.height / 2)) + dualScreenTop;

  return Object.keys(authWindowSpecs).map((param) => `${param.toString()}=${authWindowSpecs[param]}`).join(',');
};

const getRedirectUrl = (domain: string) => `https://petdna.${domain}/api/auth/sso`;
const getSSOUrlSignIn = (domain: string): string => {
  const redirectUrl = getRedirectUrl(domain);

  return appendSearchParams(
    new URL(`https://www.${domain}/sso/oidc/authorize`),
    {
      redirect_uri: redirectUrl,
      response_type: 'code',
      rtype: 120,
      client_id: '0b7d32f5c4fa005c3b37be6401b14c83480b6b75',
      scope: 'openid',
    },
  ).toString();
};

const getSSOUrlSignUp = (domain: string): string => {
  const baseUrl = getSSOUrlSignIn(domain);

  return appendSearchParams(
    new URL(`https://www.${domain}/account/create`),
    {
      flowId: 'sso-petdna',
      returnUrl: baseUrl,
    },
  ).toString();
};

export const useAncestryLogin = (
  signInType: SignInType = SignInType.SignIn,
  returnUrl?: string,
) => {
  const { onLogin } = useAuth();
  const domain = getDomain();

  const ssoUrl = useMemo(() => (signInType === SignInType.SignIn
    ? getSSOUrlSignIn(domain)
    : getSSOUrlSignUp(domain)
  ), [signInType, domain]);

  return useCallback(() => {
    const w = window.open(ssoUrl, 'Ancestry Sign in', getAuthWindowSpecs());

    const timer = setInterval(() => {
      // The window will not send a close event if the urls are from different domains.
      // This is a cross domain solution.
      if (w && w.closed) {
        if (onLogin) {
          onLogin(returnUrl);
        }
        clearInterval(timer);
      }
    }, 1000);
  }, [onLogin, ssoUrl, returnUrl]);
};
