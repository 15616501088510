import { Link } from 'react-router-dom';
import {
  ELinkHrefOption,
  ILibUIComponentsComponents,
  TLibUIComponentsUseLogin,
  TLinkComponent,
} from 'petdna-lib-uicomponents/types';
import { SignInType, useAncestryLogin } from 'hooks/useAncestryLogin';

export const libUIComponentsComponents: ILibUIComponentsComponents = {
  Link: Link as React.ComponentType<TLinkComponent>,
  LinkProps: {
    href: ELinkHrefOption.to,
  },
};

export const useLibUIComponentsLogin: TLibUIComponentsUseLogin = (
  returnUrl?: string,
) => useAncestryLogin(SignInType.Signup, returnUrl);
