import { HYGRAPH_DASHBOARD_HEALTH_REPORT_ID } from 'constants/hygraphShemeId';
import { colorsFragment, richTextFragment } from 'petdna-lib-uicomponents/hygraph';
import { KIT_PRICE_FRAGMENT } from './kitPricesQuery';

export const GET_DASHBOARD_HEALTH_REPORT_QUERY = `query DashboardHealthReport($id: ID = "${HYGRAPH_DASHBOARD_HEALTH_REPORT_ID}", $locale: [Locale!]!) {
  dashboardHealthReport(where: { id: $id }) {
    title
    description
    buttonText
    healthTraitResultTypes {
      id
      traitPredictionId
      titlePlural
      description {
        ${richTextFragment}
      }
      backgroundColor {
        ${colorsFragment}
      }
      textColor {
        ${colorsFragment}
      }
    }
    unlock {
      title
      description {
        ${richTextFragment}
      }
      buttonText
    }
    healthReportPrice(locales: $locale) {
      locale
      ${KIT_PRICE_FRAGMENT}
    }
  }
}
`;
