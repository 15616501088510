type TImageFit = 'clip' | 'crop' | 'scale' | 'max';

interface IImageResizeInput {
  width: number
  height: number
  fit: TImageFit
}

interface IImageTransformationInput {
  resize: Partial<IImageResizeInput>
}

type TDocumentFileTypes = 'jpg'
| 'png'
| 'svg'
| 'webp'
| 'pdf'
| 'odp'
| 'ods'
| 'odt'
| 'txt'
| 'docx'
| 'html'
| 'doc'
| 'xlsx'
| 'xls'
| 'pptx'
| 'ppt';

interface IDocumentOutputInput {
  format: TDocumentFileTypes
}

interface IDocumentTransformationInput {
  output: IDocumentOutputInput
}

export interface ITransformation {
  image?: IImageTransformationInput;
  document?: IDocumentTransformationInput;
  validateOptions?: boolean;
}

export const getTransformationImageObj = (width?: number, height?: number, fit?: TImageFit) => {
  const resize = { width, height, fit };

  return { image: { resize } };
};

export const urlWithTransformation = ({
  image,
  document,
  validateOptions = false,
}: ITransformation) => {
  if (!image?.resize && !document?.output) return 'url';

  const imageString = image?.resize ? `image: ${JSON.stringify(image).replaceAll('"', '')}` : '';
  const documentString = document?.output ? `document: ${JSON.stringify(image).replaceAll('"', '')}` : '';

  return `url(transformation: {
    ${imageString}
    ${documentString}
    validateOptions: ${validateOptions}
  })`;
};
