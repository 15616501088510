export type TypeLCID = {
  [key: number]: string
};

export const LCID: TypeLCID = {
  2058: 'es-MX',
  2057: 'en-GB',
  3081: 'en-AU',
  4105: 'en-CA',
  1033: 'en-US',
  6153: 'en-IE',
  5129: 'en-NZ',
  3084: 'fr-CA',
  1031: 'de-DE',
  1053: 'sv-SE',
  1036: 'fr-FR',
};

export const DEFAULT_COUNTRY_CODE_KEY = 'US';
export const US_COUNTRY_CODE = 'US';
export const SUPPORTED_COUNTRY_CODES = ['US', 'GB', 'NZ', 'AU', 'IE'] as const;
export const COUNTRY_CODE_TO_LOCALE = {
  US: 'en_US',
  GB: 'en_GB',
  NZ: 'en_NZ',
  AU: 'en_AU',
  IE: 'en_IE',
  en: 'en',
} as const;
export type TSupportedCountryCodes = typeof SUPPORTED_COUNTRY_CODES[
  Exclude<keyof typeof SUPPORTED_COUNTRY_CODES, keyof Array<typeof SUPPORTED_COUNTRY_CODES>>
];
export type TSupportedLocales = typeof COUNTRY_CODE_TO_LOCALE[keyof typeof COUNTRY_CODE_TO_LOCALE];
