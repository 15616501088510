import {
  action, configure, makeAutoObservable, observable,
  runInAction,
} from 'mobx';
import { UserProfile } from 'stores/types/Profiles';
import apiClient from '../apiClient';

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class UserStore {
  userProfile: UserProfile | null = null;
  isUserProfileLoaded = false;

  constructor() {
    makeAutoObservable(this, {
      userProfile: observable,
      isUserProfileLoaded: observable,
      getUserProfile: action,
      setUserProfile: action,
      setPetDNADisplayName: action,
      setMeasurementPreference: action,
      reloadProfile: action,
    });
  }

  getUserProfile = async () => {
    this.isUserProfileLoaded = false;
    const profileResponse = await apiClient.user.getUserProfile();
    if (profileResponse?.data) {
      this.userProfile = profileResponse.data;
      this.isUserProfileLoaded = true;
    }
  };

  reloadProfile = async () => {
    const profileResponse = await apiClient.user.getUserProfile();
    runInAction(() => {
      if (profileResponse?.data) {
        this.userProfile = profileResponse.data;
      }
    });
  };

  setPetDNADisplayName = async (displayName:string) => {
    await apiClient.user.setPetDNADisplayName(displayName);
    await this.reloadProfile();
  };

  setMeasurementPreference = async (measurementPreference:string) => {
    const profileResponse = await apiClient.user.setMeasurementPreference(measurementPreference);
    if (profileResponse.data) {
      this.userProfile = profileResponse.data;
    }
  };

  setAllMessagesOn = async (isDoNotContact:boolean) => {
    const profileResponse = await apiClient.user.setAllMessagesOn(isDoNotContact);
    if (profileResponse.data) {
      this.userProfile = profileResponse.data;
    }
  };

  setUserProfile(profile: UserProfile | null) {
    this.userProfile = profile;
  }

  setUserPhoneNumber = async (phoneNumber: string, phoneCountryCode: string) => {
    await apiClient.user.setPhoneNumber(phoneNumber, phoneCountryCode);
    await this.reloadProfile();
  };
}

export default UserStore;
