import {
  action,
  configure, makeAutoObservable, observable,
  runInAction,
} from 'mobx';
import apiClient from 'apiClient/index';
import {
  IPageHome,
  TNavbar,
  TPageConsent,
  TPageInterimDashboardWrapper,
  TPolicyPage,
  IPageLoggedInDashboard,
  ICookieDisclosure,
  IPageOffer,
  IPagePartnerships,
  TPageCoBrandedSections,
} from 'stores/types/Pages';
import { IKitPrice } from 'stores/types/Hygraph';
import { TSupportedLocales } from 'constants/LCID';
import { ITopPagePromoBanner } from 'petdna-lib-uicomponents/types';
import {
  ISectionDashboardCarousel,
  ISectionDashboardPartnership,
} from 'stores/types/Sections';
import { EUnionPageName, IUnionPage } from 'petdna-lib-uicomponents/hygraph';

type IUnionPageStore<T> = {
  [key in EUnionPageName]: T;
};

const generateUnionPageStore = <T>(initialValue: T): IUnionPageStore<T> => {
  const store: IUnionPageStore<T> = {} as IUnionPageStore<T>;
  return Object.keys(EUnionPageName).reduce((o, k) => ({ ...o, [k]: initialValue }), store);
};

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class PagesStore {
  pageConsent: TPageConsent | null = null;
  pageConsentLoaded = false;
  pageHome: IPageHome | null = null;
  pageHomeLoaded = false;
  pageLoggedInDashboard: IPageLoggedInDashboard | null = null;
  pageLoggedInDashboardLoaded: boolean = false;
  pagePartnerships: IPagePartnerships | null = null;
  pagePartnershipsLoaded: boolean = false;
  navbarData: TNavbar | null = null;
  isNavbarLoaded: boolean = false;
  interimDashboardData: TPageInterimDashboardWrapper | null = null;
  interimDashboardDataLoaded: boolean = false;
  loadingInterimDashboardData: boolean = false;
  isInterimDashboardVisited: boolean = false;
  footerData: any = null;
  kitPrices: IKitPrice[] = [];
  unionPages: IUnionPageStore<IUnionPage | null> = generateUnionPageStore<IUnionPage | null>(null);
  isFooterLoaded: boolean = false;
  areKitPricesLoaded: boolean = false;
  policyData: TPolicyPage | null = null;
  cookiesMessage: ICookieDisclosure | null = null;
  pageOffer: IPageOffer | null = null;
  pageOfferLoaded = false;
  pageCoBranded: TPageCoBrandedSections | [] = [];
  pageCoBrandedLoaded: boolean = false;
  promoBanner: ITopPagePromoBanner | null = null;
  promoBannerLoaded: boolean = false;
  unionPagesLoaded: IUnionPageStore<boolean> = generateUnionPageStore<boolean>(false);
  sectionDashboardPartnership: ISectionDashboardPartnership | null = null;
  sectionDashboardCarousel: ISectionDashboardCarousel | null = null;

  constructor() {
    makeAutoObservable(this, {
      pageHome: observable,
      pageHomeLoaded: observable,
      pageLoggedInDashboard: observable,
      pageLoggedInDashboardLoaded: observable,
      navbarData: observable,
      isNavbarLoaded: observable,
      isFooterLoaded: observable,
      footerData: observable,
      kitPrices: observable,
      areKitPricesLoaded: observable,
      interimDashboardData: observable,
      policyData: observable,
      interimDashboardDataLoaded: observable,
      loadingInterimDashboardData: observable,
      sectionDashboardPartnership: observable,
      setIsInterimDashboardVisited: action,
      getKitPrices: action,
      cookiesMessage: observable,
      pageOfferLoaded: observable,
      pageOffer: observable,
      getPageOffer: action,
      getSectionDashboardPartnership: action,
    });
  }

  getPageConsent = async () => {
    if (!this.pageConsentLoaded) {
      const response = await apiClient.page.getRegistrationConsent();
      runInAction(() => {
        if (response?.data?.data?.pageRegistrationConsent) {
          this.pageConsent = response.data.data.pageRegistrationConsent;
        }
        this.pageConsentLoaded = true;
      });
    }
  };

  getPageHome = async () => {
    if (!this.pageHomeLoaded) {
      const response = await apiClient.page.getPageHome();
      if (response?.data?.data?.pageLiMarketing) {
        this.pageHome = response.data.data.pageLiMarketing;
      }
      this.pageHomeLoaded = true;
    }
  };

  getPageOffer = async (cid: string) => {
    if (!this.pageOfferLoaded) {
      const response = await apiClient.page.getPageOffer(cid);
      if (response?.data?.data?.pageLiOffer) {
        this.pageOffer = response.data.data.pageLiOffer;
      }
      this.pageOfferLoaded = true;
    }
  };

  getPageLoggedInDashboard = async () => {
    if (!this.pageLoggedInDashboard) {
      const response = await apiClient.page.getPageLoggedInDashboard();
      if (response?.data?.data?.pageLiDashboard) {
        this.pageLoggedInDashboard = response?.data?.data.pageLiDashboard;
      }
      this.pageLoggedInDashboardLoaded = true;
    }
  };

  getPartnerships = async (locale: TSupportedLocales[]) => {
    if (!this.pagePartnershipsLoaded) {
      const response = await apiClient.page.getPagePartnerships(locale);
      runInAction(() => {
        if (response.data.data.pagePartnership) {
          this.pagePartnerships = response?.data?.data.pagePartnership;
        }
        this.pagePartnershipsLoaded = true;
      });
    }
  };

  getFooter = async () => {
    if (!this.isFooterLoaded) {
      this.isFooterLoaded = true;
      const response = await apiClient.page.getFooter();
      if (response?.data?.data?.footer) {
        this.footerData = response.data.data.footer;
      }
    }
  };

  getNavbar = async () => {
    if (!this.isNavbarLoaded) {
      this.isNavbarLoaded = true;
      const response = await apiClient.page.getNavbar();
      if (response?.data?.data?.topNav) {
        this.navbarData = response.data.data.topNav;
      }
    }
  };

  getKitPrices = async () => {
    if (!this.areKitPricesLoaded) {
      this.areKitPricesLoaded = true;
      const response = await apiClient.page.getKitPrices();
      if (response?.data?.data?.kitPrices) {
        this.kitPrices = response.data.data.kitPrices;
      }
    }
  };

  getInterimDashboardData = async () => {
    this.loadingInterimDashboardData = true;
    const interimDashboardDataResponse = await apiClient.page.getInterimDashboardPage();
    if (interimDashboardDataResponse?.data) {
      this.interimDashboardData = interimDashboardDataResponse?.data?.data;
      this.loadingInterimDashboardData = false;
    }
    this.interimDashboardDataLoaded = true;
  };

  getPolicyData = async (policyId: string) => {
    const policyData = await apiClient.page.getPolicy(policyId);
    if (policyData?.data) {
      this.policyData = policyData?.data?.data?.policy;
    }
  };

  getCookiesDisclosureMessage = async () => {
    const cookiesMessage = await apiClient.page.getCookiesDisclosure();
    if (cookiesMessage?.data) {
      this.cookiesMessage = cookiesMessage?.data?.data?.pageAccount;
    }
  };

  setIsInterimDashboardVisited = () => {
    this.isInterimDashboardVisited = true;
  };

  getCoBranded = async (name: string | null, locale: TSupportedLocales) => {
    if (this.pageCoBrandedLoaded) return;

    if (!name) {
      this.pageCoBrandedLoaded = true;
      return;
    }

    const response = await apiClient.page.getPageCoBranded(name, locale);
    runInAction(() => {
      if (response?.data?.data.pageCoBranded) {
        this.pageCoBranded = response?.data?.data.pageCoBranded.sections;
      }
      this.pageCoBrandedLoaded = true;
    });
  };

  getPromoBanner = async (enabled: boolean, locale: TSupportedLocales[]) => {
    if (this.promoBannerLoaded) return;

    const response = await apiClient.page.getPromoBanners(enabled, locale, 'LIHP');
    runInAction(() => {
      if (response?.data?.data.promoBanners) {
        this.promoBanner = response?.data?.data.promoBanners[0] ?? null;
      }
      this.promoBannerLoaded = true;
    });
  };

  getSectionDashboardPartnership = async () : Promise<ISectionDashboardPartnership | null> => {
    if (this.sectionDashboardPartnership) {
      return this.sectionDashboardPartnership;
    }

    const response = await apiClient.page.getSectionDashboardPartnership();
    return runInAction(() => {
      if (response?.data?.data.sectionDashboardPartnerships) {
        this.sectionDashboardPartnership = response?.data?.data.sectionDashboardPartnerships[0]
          ?? null;
      }

      return this.sectionDashboardPartnership;
    });
  };

  getSectionDashboardCarousel = async (
    locales: TSupportedLocales[],
  ): Promise<ISectionDashboardCarousel | null> => {
    if (this.sectionDashboardCarousel) {
      return this.sectionDashboardCarousel;
    }

    const response = await apiClient.page.getSectionDashboardCarousel(locales);
    return runInAction(() => {
      if (response?.data?.data.sectionDashboardCarousels) {
        this.sectionDashboardCarousel = response.data.data.sectionDashboardCarousels[0] ?? null;
      }

      return this.sectionDashboardCarousel;
    });
  };

  getUnionPage = async (page: EUnionPageName) => {
    if (!this.unionPagesLoaded[page]) {
      const response = await apiClient.page.getUnionPage({ page });
      if (response?.data?.data?.unionPage) {
        this.unionPages[page] = response.data.data.unionPage;
      }
      this.unionPagesLoaded[page] = true;
    }
  };
}

export default PagesStore;
