import { getTransformationImageObj, ITransformation } from 'helpers/hygraphAssetTransformation';
import { colorsFragment, richTextFragment, getImageQueryFragment } from 'petdna-lib-uicomponents/hygraph';

export const getColorQuery = (fieldName: string): string => `
  ${fieldName} {
    ${colorsFragment}
  }
`;

export const getButtonQuery = (fieldName: string): string => `
  ${fieldName} {
    text
    
    ${getColorQuery('textColor')}
    ${getColorQuery('backgroundColor')}
    ${getColorQuery('borderColor')}
    
    borderWidth
    borderRadius
    fontSize
    
    sizeUnits
    externalUrl
  }
`;

export const getExternalLinkQuery = (fieldName: string): string => `
  ${fieldName} {
    text
    externalUrl
    
    ${getColorQuery('textColor')}
  }
`;

export const getImageQuery = (fieldName: string, transformation: ITransformation): string => `
  ${fieldName} {
    ${getImageQueryFragment(transformation)}
  }
`;

export const SECTION_DASHBOARD_CAROUSEL_FULL_IMAGE_TEXT_ITEM_FRAGMENT = `
  ... on SectionDashboardCarouselFullImageTextItem {
    itemLocale
    sectionTitle
    
    ${getImageQuery('image', {})}
    
    title
    description {
      ${richTextFragment}
    }
    
    ${getExternalLinkQuery('externalLink')}
    
    ${getColorQuery('textColor')}
    ${getColorQuery('paginationColor')}
    ${getColorQuery('sectionTitleColor')}
  }
`;
export const SECTION_DASHBOARD_CAROUSEL_HALF_IMAGE_TEXT_ITEM_FRAGMENT = `
  ... on SectionDashboardCarouselHalfImageTextItem {
    itemLocale
    sectionTitle
    
    ${getImageQuery('image', {})}
    
    title
    description {
      ${richTextFragment}
    }
    
    ${getButtonQuery('button')}
    
    ${getColorQuery('textColor')}
    ${getColorQuery('backgroundColor')}
    ${getColorQuery('paginationColor')}
    ${getColorQuery('sectionTitleColor')}
  }
`;
export const SECTION_DASHBOARD_CAROUSEL_TEXT_ONLY_ITEM_FRAGMENT = `
  ... on SectionDashboardCarouselTextOnlyItem {
    itemLocale
    sectionTitle
    
    title
    description {
      ${richTextFragment}
    }
    
    ${getButtonQuery('button')}
    
    ${getColorQuery('textColor')}
    ${getColorQuery('topBackgroundColor')}
    ${getColorQuery('bottomBackgroundColor')}
    ${getColorQuery('paginationColor')}
    ${getColorQuery('sectionTitleColor')}
  }
`;

export const SECTION_DASHBOARD_CAROUSEL_QUERY = `
  query SectionDashboardCarousel {
    sectionDashboardCarousels(first: 1) {
      nextItemTimeout
      
      emptyStateTitle
      ${getImageQuery('emptyStateImage', getTransformationImageObj(480, 240))}
      ${getColorQuery('emptyStateBackgroundColor')}
      
      ${getColorQuery('paginationColor')}
      
      items {
        __typename
        ${SECTION_DASHBOARD_CAROUSEL_FULL_IMAGE_TEXT_ITEM_FRAGMENT}
        ${SECTION_DASHBOARD_CAROUSEL_HALF_IMAGE_TEXT_ITEM_FRAGMENT}
        ${SECTION_DASHBOARD_CAROUSEL_TEXT_ONLY_ITEM_FRAGMENT}
      }
    }
  }
`;
