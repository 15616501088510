import {
  HealthTraitListFilters,
  HealthTraitListFiltersAllValues,
} from 'routes/HealthReport/components/Filters/Filters';
import {
  HealthTraitInfo,
  HealthTraitInheritance,
  HealthTraitRecord,
  Trait,
} from 'stores/types/Traits';
import { useEffect, useMemo, useState } from 'react';
import { useStores } from 'stores/useStores';
import useHealthFeature from 'hooks/useHealthFeature';

const DEFAULT_PER_PAGE = 10;

export enum HealthTraitPrediction {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  UNDETERMINED = 'UNDETERMINED',
}

export const TRAIT_RESULT_TYPE_ORDER: any = {
  [HealthTraitPrediction.GREEN]: 1,
  [HealthTraitPrediction.YELLOW]: 2,
  [HealthTraitPrediction.RED]: 3,
  [HealthTraitPrediction.UNDETERMINED]: 0,
};

const compareHealthTraitRecords = (l: HealthTraitRecord, r: HealthTraitRecord) => {
  if (r.type.traitPredictionId !== l.type.traitPredictionId) {
    return TRAIT_RESULT_TYPE_ORDER[r.type.traitPredictionId]
      - TRAIT_RESULT_TYPE_ORDER[l.type.traitPredictionId];
  }

  if (r.type.traitPredictionId === HealthTraitPrediction.GREEN
    && r.info.inheritance !== l.info.inheritance) {
    return r.info.inheritance === HealthTraitInheritance.COMPLEX ? 1 : -1;
  }

  if (l.info.title > r.info.title) {
    return -1;
  }

  if (l.info.title < r.info.title) {
    return 1;
  }

  return 0;
};

export interface UseHealthTraitRecordsOptions {
  testId?: string;
  filters: HealthTraitListFilters | null;
  allFilterValues: HealthTraitListFiltersAllValues | null;
  page?: number;
  perPage?: number;
}

export interface HealthTraitRecordsResponse {
  records?: HealthTraitRecord[];
  allRecords?: HealthTraitRecord[];
  total: number;
  page: number;
  loading: boolean;
  predictions: Trait[] | null;
}

const useHealthTraitRecords = ({
  filters,
  allFilterValues,
  page = 1,
  perPage = DEFAULT_PER_PAGE,
}: UseHealthTraitRecordsOptions): HealthTraitRecordsResponse => {
  const { healthReportStore } = useStores();
  const [loading, setLoading] = useState(true);
  const [
    healthTraitsInfoList,
    setHealthTraitsInfoList,
  ] = useState<HealthTraitInfo[] | null>(null);

  const {
    predictions,
    loading: healthPredictionsLoading,
  } = useHealthFeature();
  const predictionsById = useMemo(() => predictions?.reduce((acc, p) => {
    acc[p.id.toLowerCase()] = p;
    return acc;
  }, {} as Record<string, Trait>), [predictions]);

  useEffect(() => {
    if (!allFilterValues || !predictionsById || !filters) {
      return () => {};
    }

    let rendered = true;
    setLoading(true);
    healthReportStore.listHealthTraitsInfo({
      traitIds: filters.statuses
        ? Object.keys(predictionsById)
          .filter(
            (id) => filters
              .statuses
              ?.find((status) => predictionsById[id].prediction.endsWith(status.traitPredictionId)),
          )
        : undefined,
      breedIds: filters.breeds
        ? filters.breeds.map(({ id }) => id)
        : undefined,
      bodyFunctionIds: filters.bodyFunctions
        ? filters.bodyFunctions?.map(({ id }) => id)
        : undefined,
      query: filters.search,
    })
      .then((healthTraits) => {
        if (!rendered) {
          return;
        }

        setHealthTraitsInfoList(healthTraits);
      })
      .finally(() => {
        if (!rendered) {
          return;
        }

        setLoading(false);
      });

    return () => {
      rendered = false;
    };
  }, [allFilterValues, filters, healthReportStore, predictionsById]);

  const allRecords = useMemo(() => (
    predictionsById && healthTraitsInfoList && allFilterValues
      ? healthTraitsInfoList
        .filter((rec) => !!predictionsById[rec.traitId])
        .map((rec) => ({
          info: rec,
          prediction: predictionsById[rec.traitId],
          type: allFilterValues
            .statuses
            .find(
              (status) => predictionsById[rec.traitId]
                .prediction
                .includes(status.traitPredictionId),
            )!,
        }))
        .sort(compareHealthTraitRecords)
      : undefined
  ), [allFilterValues, healthTraitsInfoList, predictionsById]);

  return useMemo(() => ({
    predictions,
    allRecords,
    records: allRecords?.slice((page - 1) * perPage, page * perPage),
    page,
    total: allRecords?.length ?? 0,
    loading: loading || healthPredictionsLoading,
  }), [
    predictions,
    allRecords,
    page,
    perPage,
    loading,
    healthPredictionsLoading,
  ]);
};

export default useHealthTraitRecords;
